import React, { useState, useEffect } from "react";
import HeroSection from "../components/homecomponents/heroSection";
import WhatWeOffer from "../components/homecomponents/whatWeOffer";
import ProductsSection from "../components/homecomponents/productsSection";
import JoinUs from "../components/homecomponents/joinUs";
import ContactUs from "../components/homecomponents/contactUs";
import MyCountdown from "../components/homecomponents/myCountdown";
import AfterAndLater from "../components/homecomponents/afterAndLater";

const Home = () => {
  const [showButton, setShowButton] = useState(false);

  // Mostra il pulsante quando l'utente scorre verso il basso
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Funzione per scrollare in cima
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="flex flex-col">
      <MyCountdown />
      <HeroSection />
      <WhatWeOffer />
      <ProductsSection />
      <AfterAndLater />
      <JoinUs />
      <ContactUs />

      {/* Pulsante per tornare in cima */}
      {showButton && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-20 right-6 p-3 bg-black text-white rounded-full shadow-lg hover:bg-white hover:text-black transition"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="4"
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
            />
          </svg>
        </button>
      )}
    </div>
  );
};

export default Home;
