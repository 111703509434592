import React from "react";

const Promo = () => {
  return (
    <div className="relative w-full h-[100vh]">
      <iframe
        className="absolute border-none w-full h-full left-0 top-0"
        src="https://online.fliphtml5.com/cauwr/mxyo/"
        seamless="seamless"
        scrolling="no"
        frameBorder="0"
        allowTransparency="true"
        allowFullScreen="true"
        title="Depliant Natale"
      ></iframe>
    </div>
  );
};

export default Promo;
