import React, { useEffect } from "react";

const DialogflowChatbot = () => {
  useEffect(() => {
    // Verifica se lo script è già presente nel DOM per evitare duplicati
    if (
      !document.querySelector(
        'script[src="https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1"]'
      )
    ) {
      const script = document.createElement("script");
      script.src =
        "https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1";
      script.async = true;
      document.body.appendChild(script);

      script.onload = () =>
        console.log("Script Dialogflow Messenger caricato con successo.");
      script.onerror = (e) =>
        console.error(
          "Errore nel caricamento dello script Dialogflow Messenger:",
          e
        );
    }
  }, []);

  return (
    <div className="fixed z-20">
      <df-messenger
        intent="WELCOME"
        chat-title="Assistente Bevilacqua Serramenti"
        agent-id="735db306-6d60-4edd-bd28-1d5d6e937904"
        language-code="it"
      ></df-messenger>
    </div>
  );
};

export default DialogflowChatbot;
