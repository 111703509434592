import { useState } from "react";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import bgImage from "../../assets/images/banner_promo.png";

const MyCountdown = () => {
  const [isVisible, setIsVisible] = useState(true);

  const onComplete = () => {
    setIsVisible(false); // Nasconde il div principale quando il countdown termina
  };

  const renderer = ({ days, hours, minutes, seconds }) => (
    <div
      style={{ backgroundImage: `url(${bgImage})` }}
      className="text-white p-6 shadow-lg text-center relative z-10 bg-cover bg-center"
    >
      {/* Titolo */}
      <h2 className="bg-red-700 bg-opacity-70 text-3xl font-bold uppercase tracking-wide text-white py-2 px-4 w-fit mx-auto">
        L'offerta Natalizia termina tra:
      </h2>

      {/* Timer */}
      <div className="flex justify-center space-x-4 text-4xl font-bold pb-5">
        <div className="flex flex-col items-center">
          <span className="text-red-700">{days}</span>
          <span className="text-sm uppercase">Giorni</span>
        </div>
        <div className="flex flex-col items-center">
          <span className="text-red-700">{hours}</span>
          <span className="text-sm uppercase">Ore</span>
        </div>
        <div className="flex flex-col items-center">
          <span className="text-red-700">{minutes}</span>
          <span className="text-sm uppercase">Minuti</span>
        </div>
        <div className="flex flex-col items-center">
          <span className="text-red-700">{seconds}</span>
          <span className="text-sm uppercase">Secondi</span>
        </div>
      </div>

      {/* Pulsante */}
      <Link
        to="/promo"
        className="bg-red-700 text-white py-2 px-4 rounded-lg hover:bg-white hover:text-red-700 transition duration-300 font-medium inline-flex items-center"
      >
        Approfitta ora!
      </Link>
    </div>
  );

  return (
    isVisible && (
      <Countdown
        date={new Date("2025-01-07T00:00:00").getTime()}
        renderer={renderer}
        onComplete={onComplete} // Chiama questa funzione al termine del countdown
      />
    )
  );
};

export default MyCountdown;
