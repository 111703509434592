import React from "react";
import ReactCompareImage from "react-compare-image";
import doorLeft from "../../assets/images/doorAcetoAfter.jpg";
import doorRight from "../../assets/images/doorAcetoLater.jpg";

const AfterAndLater = () => {
  return (
    <div className="flex flex-col justify-center items-center w-full my-8">
      <div className="mb-10">
        <h1 className="font-bold text-5xl">Prima e dopo</h1>
      </div>

      <div className="max-w-sm w-full px-5 md:px-0">
        <ReactCompareImage leftImage={doorLeft} rightImage={doorRight} />
      </div>
    </div>
  );
};

export default AfterAndLater;
